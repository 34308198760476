<template>
    <div class="settings-group" :class="{ highlight: isOpen }">
        <button class="settings-group__header" @click="toggleItem(group)">
            <span class="settings-group__header-title">{{ $t(`settings.${group}.value`) }}</span>
            <span class="settings-group__header-more material-icons" :class="{ 'is-open': isOpen }">
                expand_more
            </span>
        </button>
        <div class="settings-group__content" :class="{ 'is-open': isOpen }">
            <div class="settings-group__content-wrapper">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingsGroup',
    props: {
        group: { type: String, default: 'Item' },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleItem() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/variables/system-colors.scss';

.settings-group {
    &.highlight {
        background: lighten($item-hover, 2%);
        z-index: 1;
        &__header {
            background: darken($item-hover, 10%);
        }
    }
    &.highlight &__header {
        background: $item-active;
    }
    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px;
        min-height: 56px;
        background: none;
        cursor: pointer;
        position: relative;
        transition: $transition-fast;
        border: none;

        &:hover {
            background: #edf5fc;
        }

        &-title {
            font-size: 1.1429rem;
            color: #2c2d2e;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: auto;
        }

        &-more {
            cursor: pointer;
            color: inherit;
            &.is-open {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: block;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;

        &.is-open {
            max-height: 7000px;
            opacity: 1;
            pointer-events: auto;
        }

        &-wrapper {
            padding: 16px;
        }
    }
}
</style>
