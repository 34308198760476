<template>
    <div class="option-list-settings">
        <SettingsGroup v-for="(group, index) in groups" :key="group" :group="group">
            <template v-if="group === 'styles'">
                <div :title="$t(`settings.${group}.color_scheme`)" class="option-list-settings__item-field">
                    <FormItem v-bind="{ label: 'Font family headlines' }">
                        <Select
                            :items="current[group].font_family_head.options.variants"
                            v-model="current[group].font_family_head.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: 'Font family body' }">
                        <Select
                            :items="current[group].font_family_body.options.variants"
                            v-model="current[group].font_family_body.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: $t('settings.styles.content_width') }">
                        <Select
                            :items="current[group].content_width.options.variants"
                            v-model="current[group].content_width.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: $t('settings.styles.border_radius_block') }">
                        <InputNumberAndRange
                            :items="current[group].border_radius_block.options.variants"
                            :min="current[group].border_radius_block.options.min"
                            :max="current[group].border_radius_block.options.max"
                            v-model="current[group].border_radius_block.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: $t('settings.styles.border_radius_btn') }">
                        <InputNumberAndRange
                            :items="current[group].border_radius_btn.options.variants"
                            :min="current[group].border_radius_btn.options.min"
                            :max="current[group].border_radius_btn.options.max"
                            v-model="current[group].border_radius_btn.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: $t('settings.styles.border_radius_input') }">
                        <InputNumberAndRange
                            :items="current[group].border_radius_input.options.variants"
                            :min="current[group].border_radius_input.options.min"
                            :max="current[group].border_radius_input.options.max"
                            v-model="current[group].border_radius_input.value"
                        />
                    </FormItem>
                    <br />
                    <FormItem v-bind="{ label: 'Color Scheme' }">
                        <Select :items="current[group].scheme.options.variants" v-model="current[group].scheme.value" />
                    </FormItem>
                    <br />
                    <span style="font-weight: 600;">Customize action colors:</span>
                    <br /><br />

                    <div v-for="colorGroup in Object.keys($options.customizableColorGroups)" :key="colorGroup">
                        <p class="option-list-settings__item-subgroup-title">{{ colorGroup }}</p>
                        <div v-for="(color, index) in $options.customizableColorGroups[colorGroup]" :key="index">
                            <FormItem :label="$t(`settings.${group}.${color}`)">
                                <ColorPicker
                                    v-if="current[group][color]"
                                    v-model="current[group][color].value"
                                ></ColorPicker>
                            </FormItem>
                            <br />
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="group === 'additional'">
                <OptionsViewEdit />
            </template>
            <template v-else>
                <div
                    v-for="(setting, name) in current[group]"
                    :key="name + setting.type + index"
                    :title="$t(`settings.${group}.${name}`)"
                    class="option-list-settings__item-field"
                    v-show="showByCondition(setting)"
                >
                    <FormItem :name="name" v-bind="setting.options">
                        <component
                            :is="setting.component"
                            v-model="setting.value"
                            :name="name"
                            :group="group"
                            v-bind="setting.options"
                            v-on="getFieldEvents(setting.events)"
                            @update-field="onUpdateFieldValue"
                            :disabled="disabledByHandler(setting.options)"
                        ></component>
                    </FormItem>
                </div>
            </template>
        </SettingsGroup>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import validateField from '@/helpers/validator';
import * as colorSchemes from '@/configs/color-schemas';
import fontFamily from '@/configs/font-family.js';
import SettingsGroup from '@/components/builder/optionList/settings-group.vue';
import FormItem from '@/components/form/item.vue';
import MenuEditor from '@/components/builder/menuEditor/index.vue';
import InputAddress from '@/components/form/controls/InputAddress.vue';
import Select from '@/components/form/controls/Select.vue';
import InputRatio from '@/components/form/controls/InputRatio';
import ColorPicker from '@/components/form/controls/ColorPicker.vue';
import OptionsViewEdit from '@/components/builder/OptionsViewEdit/OptionsViewEdit.vue';

export default {
    name: 'OptionListSettings',
    components: {
        ColorPicker,
        Select,
        SettingsGroup,
        FormItem,
        MenuEditor,
        InputAddress,
        OptionsViewEdit,
        InputRatio,
    },
    customizableColors: ['action_primary', 'action_secondary'],
    customizableColorGroups: {
        text: [
            'text_main',
            'text_body1',
            'text_body2',
            // 'text_accent',
            // 'text_neutral',
        ],
        textInDarkSection: [
            'text_main_inverted',
            'text_body1_inverted',
            'text_body2_inverted',
            // 'text_accent_inverted',
            // 'text_neutral_inverted',
        ],
        actionPrimary: ['action_primary', 'action_primary_hover', 'action_primary_pressed', 'action_primary_disabled'],
        actionSecondary: [
            'action_secondary',
            'action_secondary_hover',
            'action_secondary_pressed',
            'action_secondary_disabled',
        ],
        header: ['header_bg', 'header_bg_top_bar', 'header_bg_mobile'],
        'Main Menu': [
            'header_main_menu_bg',
            'header_main_menu_dropdown_bg',
            'action_neutral_light',
            'action_neutral_light_hover',
            'action_neutral_light_pressed',
            'action_neutral_light_disabled',
        ],
        'Form item': [
            'action_neutral_medium',
            'action_neutral_medium_hover',
            'action_neutral_medium_pressed',
            'action_neutral_medium_disabled',
        ],
        // actionNeutralDark: [
        //     'action_neutral_dark',
        //     'action_neutral_dark_hover',
        //     'action_neutral_dark_pressed',
        //     'action_neutral_dark_disabled',
        // ],
        system: ['success', 'success_bg', 'warning', 'warning_bg', 'error', 'error_bg'],
        productLabels: [
            'accent1',
            'accent1_bg',
            'accent2',
            'accent2_bg',
            'accent3',
            'accent3_bg',
            'accent4',
            'accent4_bg',
        ],
        backgrounds: ['neutral1', 'neutral2', 'neutral3', 'neutral4', 'neutral5', 'neutral6', 'neutral7'],
        other: ['whatsapp'],
    },
    data() {
        return {
            groups: ['common', 'ecommerce', 'header', 'footer', 'styles', 'socials', 'media', 'language', 'additional'],
        };
    },
    computed: {
        ...mapState('builder', {
            isHistory: 'isHistory',
        }),
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
    },
    methods: {
        ...mapActions('builder', {
            updateSettings: 'updateSettings',
        }),
        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            const option = group ? this.current?.[group]?.[name] : this.current?.[name];
            if (!option) return;

            let errors = [];
            const isValueArray = option.type === 'array' && Array.isArray(option.value);
            if (option.options.validateFunction) {
                if (isValueArray) errors = option.value.map((value) => option.options.validateFunction(value));
                else errors = option.options.validateFunction(option.value);
            } else {
                if (isValueArray) errors = option.value.map((value) => validateField(value, option.options.rules));
                else errors = validateField(option.value, option.options.rules);
            }
            //костыли для очистки пустых массивов ошибок, чтобы не посылались лишние группы настроек для сохранения
            let item = group ? this.current[group][name].options : this.current[name].options;
            if (errors.length) this.$set(item, 'errors', errors);
            else this.$delete(item, 'errors');
        }, 1000),
        updateSettingsDebounced: debounce(function(newSettings) {
            this.updateSettings(newSettings);
        }, 400),
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.current);
            }
            return fieldEvents;
        },
        updFontFamily(fontKey, newFamily) {
            if (newFamily !== 'Monserrat') {
                const newFamilyKey = newFamily.toLowerCase().replace(/\s/g, '');
                this.current.styles[fontKey].value = fontFamily[newFamilyKey];
            } else this.current.styles[fontKey].value = '';
        },
        disabledByHandler(obj) {
            const field = obj.disabledBy ? obj.disabledBy : '';
            if (!field) return false;
            const keys = field.split('.');
            const disabledByField = this.getObjByKeys(keys);

            return field.includes('imageCrop') ? this.isOriginalCropMode(disabledByField) : disabledByField;
        },
        isOriginalCropMode(option) {
            if (!option) return false;
            return option.value === 'original' ? true : false;
        },
        getObjByKeys(keys) {
            let value = this.current;
            if (!Object.keys(this.current).length) return;
            keys.forEach((key) => (value = value[key]));
            return value;
        },
        showByCondition(setting) {
            if (setting.options.showByCondition) {
                return setting.options.showByCondition(this.current);
            }
            return true;
        },
    },
    watch: {
        'current.styles.font_family_head.value'(newFamily) {
            this.updFontFamily('font_style_head', newFamily);
        },
        'current.styles.font_family_body.value'(newFamily) {
            this.updFontFamily('font_style_body', newFamily);
        },
        'current.styles.scheme.value'(newSheme) {
            if (newSheme !== 'Custom') {
                const newShemeKey = newSheme.toLowerCase().replace(/\s/g, '');
                Object.keys(colorSchemes[newShemeKey]).forEach((color) => {
                    if (this.current.styles[color]) this.current.styles[color].value = colorSchemes[newShemeKey][color];
                });
            }
        },
        current: {
            handler(newSettings) {
                if (this.isHistory) this.updateSettingsDebounced(newSettings);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.option-list-settings {
    overflow: hidden;
    margin: 0 -1 * $spacer * 4;

    &__item {
        &-field {
            margin: 0 0 $spacer * 8;

            &:last-child {
                margin: 0;
            }
        }
        &-subgroup {
            &-title {
                text-transform: capitalize;
                border-bottom: 1px solid #cececf;
            }
        }
    }
}
</style>
